import React from "react"
import styled from "styled-components"
import { Composition } from "atomic-layout"

const areas = `
icon name
text text
/ 56px auto
`

const FooterIconName = styled.div`
  font-size: 1.2em;
  text-align: center;
  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    text-align: left;
  }
`

const FooterIconText = styled.div`
  font-size: 0.8em;
  padding-left: 8px;
`

const FooterSvgIcon = styled.svg`
  border-radius: 8px;
  display: block;
  fill: ${(props) => props.theme.colors.text};
  height: 48px;
  padding: 8px;
  width: 48px;
`

const FooterIconWrapper = styled.a`
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};
  padding: 0.5rem 1rem;

  &:hover {
    ${FooterSvgIcon} {
      fill: ${(props) => props.theme.colors.primary};
    }

    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.bgAlt};
  }
`

type FooterIconProps = {
  Icon: typeof FooterSvgIcon
  name: string
  text: string
  link: string
}

const FooterIcon = ({ Icon, name, text, link }: FooterIconProps) => (
  <FooterIconWrapper href={link}>
    <Composition template={areas} alignItems="center" justifyItems="left" gap="0.5rem">
      {(Areas) => (
        <>
          <Areas.Icon>
            <Icon />
          </Areas.Icon>
          <Areas.Name>
            <FooterIconName>{name}</FooterIconName>
          </Areas.Name>
          <Areas.Text>
            <FooterIconText>{text}</FooterIconText>
          </Areas.Text>
        </>
      )}
    </Composition>
  </FooterIconWrapper>
)

export { FooterSvgIcon }
export default FooterIcon
