import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`

body {
  background-color: ${(props) => props.theme.colors.bgAlt};
  color: ${(props) => props.theme.colors.text};
}

a {
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.primaryAlt};
  }
}

dl {
  display: grid;
  column-gap: 2em;
  grid-template-columns: max-content auto;
}

dt {

  grid-column-start: 1;
  &::after { content: ": ";  }

  &::before {
    content: "•";
    padding-right: 0.5em;
  }
}

dd {
  grid-column-start: 2;
  margin-bottom: 0px;
}
`
