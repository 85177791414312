import React from "react"
import { Composition } from "atomic-layout"
import styled from "styled-components"
import { Link } from "gatsby"

const SiteTitle = styled.div`
  font-size: 3rem;
  line-height: 4rem;
  padding: 0;
  padding-right: 1rem;
  color: ${(props) => props.theme.colors.primary};

  a {
    text-decoration: none;
  }

  background: linear-gradient(
      ${(props) => props.theme.colors.bgAlt},
      ${(props) => props.theme.colors.bgAlt}
    ),
    linear-gradient(to bottom, #f5945c, #fec76f, #b3be62, #75ba75, #6dbfb8, #71a3c1, #be95be);

  background-origin: padding-box, border-box;
  background-repeat: no-repeat; /* this is important */
  border-right: 5px solid transparent;
`
const Letter = styled.span`
  &:hover {
    text-decoration: underline;
  }
`

const A = styled(Letter)`
  color: #f5945c;
`

const B = styled(Letter)`
  color: #fec76f;
`

const C = styled(Letter)`
  color: #b3be62;
`
const D = styled(Letter)`
  color: #75ba75;
`
const E = styled(Letter)`
  color: #6dbfb8;
`
const F = styled(Letter)`
  color: #71a3c1;
`
const G = styled(Letter)`
  color: #be95be;
`

const SiteMotto = styled.div`
  padding-left: 1rem;
  color: ${(props) => props.theme.colors.textAlt};
  padding-right: 4rem;
`

const TheTitle = styled.div``

const areas = `
title
`

const areasM = `
title motto
`

const Title = () => (
  <Composition
    template={areas}
    templateM={areasM}
    justifyContent="center"
    marginVertical="2rem"
    alignItems="center"
  >
    {(Areas) => (
      <>
        <Areas.Title>
          <SiteTitle>
            <Link to="/">
              <TheTitle>
                <A>E</A>
                <B>y</B>
                <C>e</C> <D>D</D>
                <E>r</E>
                <F>o</F>
                <G>p</G>
                <A>p</A>
                <B>e</B>
                <C>r</C>
              </TheTitle>
            </Link>
          </SiteTitle>
        </Areas.Title>
        <Areas.Motto>
          <SiteMotto>pick colors from any website</SiteMotto>
        </Areas.Motto>
      </>
    )}
  </Composition>
)

export default Title
