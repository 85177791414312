import React from "react"
import { Link } from "gatsby"

const Issue = ({ id }: { id: number }) => {
  return <a href={`https://github.com/kepi/chromeEyeDropper/issues/{id}`}>#{id}</a>
}

type Changes = Version[]

type Version = {
  version: string
  date: Date
  changes: [string, JSX.Element][]
  info?: JSX.Element
}

const Changes: Changes = [
  {
    version: "0.5.16",
    date: new Date("Apr 1, 2023"),
    changes: [
      ["code", <>Fix incorrect type definition in background script.</>],
      ["code", <>Upgrade multiple dependencies.</>],
      ["promo", <>Replaced promoted partner with UseChatGPT.ai.</>],
    ],
  },
  {
    version: "0.5.15",
    date: new Date("Oct 16, 2022"),
    changes: [["exp", <>Replaced partner link with Toucan</>]],
  },
  {
    version: "0.5.14",
    date: new Date("Jul 31, 2022"),
    changes: [["exp", <>Added partner link to popup</>]],
  },
  {
    version: "0.5.13",
    date: new Date("Jun 19, 2022"),
    changes: [
      [
        "fix",
        <>
          Fixed copying colors from popup to clipboard (broken in last update). <Issue id={219} />,{" "}
          <Issue id={220} />
        </>,
      ],
    ],
  },
  {
    version: "0.5.12",
    date: new Date("May 31, 2022"),
    changes: [
      [
        "fix",
        <>
          Fix error when it wasn't possible to pick color with more windows open in some cases{" "}
          <Issue id={215} />
        </>,
      ],
      ["fix", <>Minor cosmetic fix for color boxes in popup</>],
    ],
  },
  {
    version: "0.5.11",
    date: new Date("May 29, 2022"),
    changes: [
      [
        "fix",
        <>
          Color boxes for new and current color in popup now display short hex code and color name
          if there is an exact match <Issue id={136} />, <Issue id={192} />.
        </>,
      ],
      [
        "code",
        <>
          Links to report bug and submit idea/change requests added to <cite>Options</cite> page
        </>,
      ],
      [
        "code",
        <>
          Added changelog link to <cite>About</cite> page.
        </>,
      ],
      ["code", <>Replaced Color Picker library with simple-color-picker</>],
      ["code", <>Switched to Yarn Modern</>],
      ["code", <>Popup rewriten to TypeScript.</>],
      [
        "code",
        <>
          Load dependencies from NPM instead of <cite>vendor</cite> including.
        </>,
      ],
      ["code", <>Updated all dependencies to latest versions.</>],
    ],
  },

  {
    version: "0.5.10",
    date: new Date("May 26, 2022"),
    changes: [
      [
        "fix",
        <>
          Should now work fine on Dribbble.com <Issue id={203} />.
        </>,
      ],
      [
        "fix",
        <>
          Fix wrong link to help pages <Issue id={180} />, <Issue id={197} /> and <Issue id={205} />
          .
        </>,
      ],
      [
        "fix",
        <>
          Hide the overflow on the X axis of the settings page, preventing an extra scrollbar.
          Contributed by Salamander0 <Issue id={198} />
        </>,
      ],

      [
        "fix",
        <>
          Fix copying colors to clipboard on macOS <Issue id={156} />
        </>,
      ],
    ],
  },
  {
    version: "0.5.8",
    date: new Date("March 22, 2021"),
    changes: [
      [
        "fix",
        <>
          Fixes critical <Issue id={166} /> - Can't pick colors from web with Eye Dropper.
        </>,
      ],
    ],
  },
  {
    version: "0.5.7",
    date: new Date("March 22, 2021"),
    changes: [
      [
        "feature",
        <>
          Picking colors from local files is now{" "}
          <Link to="/help/file-urls">possible if permitted</Link>. Fixes <Issue id={97} /> and{" "}
          <Issue id={154} />.
        </>,
      ],
      [
        "feature",
        <>
          Eye Dropper is now on <a href="https://opencollective.com/eyedropper">open collective</a>.
          Chrome Web Store Payments and Paypal donation button has been removed (fixes{" "}
          <Issue id={164} />
          ).
        </>,
      ],
      [
        "feature",
        <>
          "Installed" info page is now displayed directly from new web instead of local extension
          HTML page.
        </>,
      ],
      [
        "fix",
        <>
          Fixes <Issue id={115} /> incorrect format of HSL colors thanks to report from @jakeparis.
        </>,
      ],
      [
        "fix",
        <>
          Fix duplicated Color Picker when clicking on tab multiple times. Fixes <Issue id={108} />
        </>,
      ],
      [
        "fix",
        <>
          Updated dev dependencies, fixes <Issue id={148} />, <Issue id={150} />, <Issue id={151} />
          , <Issue id={157} /> and <Issue id={163} />.
        </>,
      ],
      [
        "code",
        <>Last dependencies on jQuery were finally removed, Eye Dropper is now jQuery free :)</>,
      ],
    ],
  },
  {
    version: "0.5.6",
    date: new Date("May 22, 2020"),
    changes: [
      [
        "fix",
        <>
          On some macOS systems web page picking stopped worked. Fixes <Issue id={128} />.
        </>,
      ],
    ],
  },
  {
    version: "0.5.5",
    date: new Date("May 7, 2020"),
    changes: [
      [
        "fix",
        <>
          Welcome page should be opened only on new install. Fixes rare cases, i.e.{" "}
          <Issue id={128} />.
        </>,
      ],
    ],
  },
  {
    version: "0.5.4",
    date: new Date("May 7, 2020"),
    changes: [
      ["feature", <>Now working with zoomed pages.</>],
      [
        "fix",
        <>
          Infamous Picking Only Black is finally away (big thanks Hannah Carrol for testing). Fixes{" "}
          <Issue id={141} />, <Issue id={134} />, <Issue id={133} />, <Issue id={132} />,{" "}
          <Issue id={131} />, <Issue id={109} />, <Issue id={103} />, <Issue id={99} />.
        </>,
      ],
      [
        "fix",
        <>
          Duplicities are no longer picked. Fixes <Issue id={135} />.
        </>,
      ],
      [
        "fix",
        <>
          Case sensitivity during visual color selection in Color Picker. Fixes <Issue id={120} />.
        </>,
      ],
      [
        "fix",
        <>
          Clarify how to change default shortcut. Fixes <Issue id={124} />.
        </>,
      ],
      ["fix", <>Hover effects should be correctly disabled in all cases.</>],
      ["code", <>Started with rewrite to TypeScript. Already helped to catch couple bugs above.</>],
      ["code", <>First tiny set of unit tests so we can catch bugs faster.</>],
      ["code", <>Content script is smaller now and easier to debug.</>],
      ["code", <>Multiple helpers for debug and building of extensions.</>],
    ],
  },
  {
    version: "0.5.3",
    date: new Date("April 28, 2019"),
    changes: [
      [
        "fix",
        <>
          empty export issue <Issue id={129} />
        </>,
      ],
    ],
  },
  {
    version: "0.5.2",
    date: new Date("September 9, 2017"),
    changes: [
      [
        "fix",
        <>
          "all my colors have dissapeared" issue <Issue id={106} />
        </>,
      ],
    ],
  },
  {
    version: "0.5.1",
    date: new Date("September 3, 2017"),
    info: (
      <>
        <strong>Eye Dropper Plus</strong> unlocking new features is now available. You can activate
        it inside extension on the Plus unlock page.
      </>
    ),
    changes: [
      ["plus", <>More color palettes. You can sort your color history in named palettes now.</>],
      ["plus", <>Palette export now have date and time when color was picked.</>],
      [
        "plus",
        <>
          Palette export now have source type of color pick (webpage, color picker or conversion)
        </>,
      ],
      ["fix", <>Remove redundant history saving and palette switches</>],
      ["fix", <>Broken recording of color history timestamps</>],
      ["fix", <>Option page behaving wrong after extension update in some minor cases</>],
      ["fix", <>Add warning for other special chrome pages</>],
      ["fix", <>Use local copy of Tachyons CSS</>],
    ],
  },
  {
    version: "0.4.3",
    date: new Date("October 6, 2016"),
    changes: [
      [
        "fix",
        <>
          Doubled color picker (see <Issue id={93} /> and <Issue id={95} />)
        </>,
      ],
      ["fix", <>use local version of tachyon.css instead of CDN</>],
    ],
  },
  {
    version: "0.4.2",
    date: new Date("August 18, 2016"),
    changes: [
      [
        "fix",
        <>
          Add color input field in color picker which was removed mistakenly during popup rewrite
          (see <Issue id={90} />)
        </>,
      ],
    ],
  },
]

export default Changes
