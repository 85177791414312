const transitions = {
  normal: "0.5s",
}

const fontSize = {
  small: "0.9rem",
  big: "2.9rem",
}

const solarized = {
  base03: "#002b36",
  base02: "#073642",
  base01: "#586e75",
  base00: "#657b83",
  base0: "#839496",
  base1: "#93a1a1",
  base2: "#eee8d5",
  base3: "#fdf6e3",
  yellow: "#b58900",
  orange: "#cb4b16",
  red: "#dc322f",
  magenta: "#d33682",
  violet: "#6c71c4",
  blue: "#268bd2",
  cyan: "#2aa198",
  green: "#859900",
}

const materialDarker = {
  base00: "#212121",
  base01: "#303030",
  base02: "#353535",
  base03: "#4A4A4A",
  base04: "#B2CCD6",
  base05: "#EEFFFF",
  base06: "#EEFFFF",
  base07: "#FFFFFF",
  base08: "#F07178",
  base09: "#F78C6C",
  base0A: "#FFCB6B",
  base0B: "#C3E88D",
  base0C: "#89DDFF",
  base0D: "#82AAFF",
  base0E: "#C792EA",
  base0F: "#FF5370",
}

const materialLighter = {
  scheme: "#Material Lighter",
  author: "#Nate Peterson",
  base00: "#FAFAFA",
  base01: "#E7EAEC",
  base02: "#CCEAE7",
  base03: "#CCD7DA",
  base04: "#8796B0",
  base05: "#80CBC4",
  base06: "#80CBC4",
  base07: "#FFFFFF",
  base08: "#FF5370",
  base09: "#F76D47",
  base0A: "#FFB62C",
  base0B: "#91B859",
  base0C: "#39ADB5",
  base0D: "#6182B8",
  base0E: "#7C4DFF",
  base0F: "#E53935",
}
export { transitions, fontSize, solarized, materialDarker, materialLighter }
