import styled from "styled-components"

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.bg};
  border: 1px solid ${(props) => props.theme.colors.bgBorder};
  border-radius: 10px;
  padding: 0rem;

  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    padding: 2rem;

    p,
    li {
      max-width: 36rem;
    }
  }
`

export default Content
