export default {
  pathPrefix: "", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: "Eye Dropper", // Navigation and Site Title
  siteTitleAlt: "Eye Dropper", // Alternative Site title for SEO
  siteDescription: "Pick colors from any website with Eye Dropper browser extension.",
  author: "Kepi.cz", // Author for schemaORGJSONLD
  siteUrl: "https://eyedropper.org", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteLogo: "/assets/logo.png", // Image for schemaORGJSONLD
  siteBanner: "/assets/banner.jpg", // Your image for og:image tag. You can find it in the /static folder
  favicon: "src/assets/eye-dropper.svg", // Your image for favicons. You can find it in the /src folder
  iconMaskable: "src/assets/maskable.svg",

  userTwitter: "@eyedropper", // Twitter Username - Optional

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: "#75ba75",
  backgroundColor: "#1d2022",
}
