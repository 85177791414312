import React from "react"
import { Helmet } from "react-helmet"
import config from "../../config/SiteConfig"
import { useLocation } from "@reach/router"

type Props = {
  title?: string
  description?: string
  slug?: string
}

const SEO = (props: Props): JSX.Element => {
  const title = props.title ? props.title : config.siteTitle
  const titleSite = props.title ? `${props.title} | ${config.siteTitle}` : config.siteTitle
  const description = props.description ? props.description : config.siteDescription
  const location = useLocation()
  const path = props.slug ? `${config.pathPrefix}${props.slug}` : location.pathname

  const url = `${config.siteUrl}${path}`

  /* let image
   * let slug
   */
  /* const realPrefix = config.pathPrefix === "/" ? "" : config.pathPrefix */

  return (
    <Helmet>
      <html lang={config.siteLanguage} />
      <title>{titleSite}</title>
      <meta name="description" content={description} />
      {/* <meta name="image" content={image} /> */}
      {/* <meta property="og:locale" content={config.ogLanguage} /> */}
      {/* <meta property="og:site_name" content={config.ogSiteName ? config.ogSiteName : ""} /> */}
      <meta property="og:url" content={url} />
      {/* {postSEO ? <meta property="og:type" content="article" /> : null} */}
      {/* <meta property="og:title" content={title} /> */}
      {/* <meta property="og:description" content={description} /> */}
      {/* <meta property="og:image" content={image} /> */}
      {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ""} /> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""} />
      <meta name="twitter:title" content={title} />
      {/* <meta name="twitter:url" content={config.siteUrl} /> */}
      <meta name="twitter:description" content={description} />
      {/* <meta name="twitter:image" content={image} /> */}
    </Helmet>
  )
}

export default SEO
