import React from "react"
import styled from "styled-components"
import { Heading3 } from "../components"
import Changes from "./Changes"

const ChangeItem = styled.li`
  list-style-type: none;
  text-indent: -5rem;
  margin-left: 4rem;

  &::before {
    text-indent: 0;
    width: 4rem;
    display: inline-block;
    font-size: 0.7rem;
    line-height: 0.8rem;
    color: ${(props) => props.theme.colors.bg};
    font-weight: bold;
    padding: 0 2px;
    border-radius: 2px;
    margin-right: 0.8rem;
    text-align: center;
  }

  &.fix::before {
    content: "✓ fix";
    background-color: #75ba75;
  }

  &.plus::before {
    content: "+ plus";
    background-color: #fec76f;
  }

  &.code::before {
    content: "code";
    background-color: #6dbfb8;
  }

  &.promo::before {
    content: "promo";
    background-color: #fec76f;
  }

  &.exp::before {
    content: "🪄 exp";
    background-color: #f5945c;
  }

  &.feature::before {
    content: "feature";
    background-color: #be95be;
  }
`

const Heading = styled(Heading3)`
  font-size: 1rem;
  background-color: ${(props) => props.theme.colors.primaryAlt};
`

const Change = ({ category, children }: { category: string; children: JSX.Element }) => {
  return <ChangeItem className={category}>{children}</ChangeItem>
}

const Changelog = ({ versions }: { versions?: number }) => {
  const changes = versions ? Changes.slice(0, versions) : Changes

  return (
    <>
      {changes.map((version, index) => {
        return (
          <div key={index}>
            <Heading>Version {version.version}</Heading>
            {version.info === undefined ? null : <p>{version.info}</p>}

            <ul>
              {version.changes.map((change: [string, JSX.Element], changeIndex: number) => (
                <Change key={changeIndex} category={change[0]}>
                  {change[1]}
                </Change>
              ))}
            </ul>
          </div>
        )
      })}
    </>
  )
}

export default Changelog
