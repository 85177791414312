import React from "react"
import styled from "styled-components"

const ModeButton = (props) => {
  return (
    <ModeButtonWrapper
      onClick={props.toggleThemeMode}
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
    >
      <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
    </ModeButtonWrapper>
  )
}

export default ModeButton

const ModeButtonWrapper = styled.button`
  background: none;
  cursor: pointer;
  :focus {
    border: 0;
    outline: none;
  }
  fill: ${(props) => props.theme.colors.text};
  stroke: ${(props) => props.theme.colors.text};
`
