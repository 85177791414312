import React from "react"
import { Box, Only } from "atomic-layout"
import { Link } from "gatsby"
import styled from "styled-components"

const MenuItem = styled(Link)`
  border-right: 1px solid ${(props) => props.theme.colors.bg};
  padding: 0 2rem;

  text-decoration: none;

  :last-of-type {
    border-right: 0;
  }
  :first-of-type {
    border-right: 1px solid ${(props) => props.theme.colors.bg};
  }
`

const MenuWrapper = styled(Box)`
  height: 2.5rem;
  background-color: ${(props) => props.theme.colors.header};
  border-bottom: 1px solid ${(props) => props.theme.colors.bgBorder};

  a {
    color: ${(props) => props.theme.colors.bg};
    font-weight: bold;
    &:hover {
      color: ${(props) => props.theme.colors.text};
    }
  }
`

const OnlyInline = styled(Only)`
  display: inline-block;
`

const Menu = () => {
  return (
    <MenuWrapper flex justifyContent="center" alignItems="center">
      <Only from="m">
        <MenuItem to="/">Home</MenuItem>
      </Only>
      <MenuItem to="/help">Help</MenuItem>
      <MenuItem to="/blog">Blog</MenuItem>
      <MenuItem to="/changelog">Change Log</MenuItem>
      <MenuItem to="/privacy">
        Privacy <OnlyInline from="m">Policy</OnlyInline>
      </MenuItem>
    </MenuWrapper>
  )
}

export default Menu
