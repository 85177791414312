import React from "react"
import styled from "styled-components"
import { Composition } from "atomic-layout"
import { Tags, Heading1, Heading2 } from "../components"
import { Link } from "gatsby"
import { Post } from "../models"

const areasMobile = `
  headline
  content
`

const areasDesktop = `
  headline headline headline meta
  thumbnail thumbnail thumbnail thumbnail
  content content content content
`

const Meta = styled.div`
  text-align: right;
  font-size: 0.8em;
`

const Parts = styled.div`
  background-color: ${(props) => props.theme.colors.bg};
  padding: 2rem;

  h2 {
    margin-top: 0;
  }
`

const Content = styled.div`
  max-width: 42rem;
`

const ContentPart = styled(Parts)`
  padding-top: 0;
`

const PostPreviewWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.bgBorder};
  border: 1px solid ${(props) => props.theme.colors.bgBorder};
`

const Thumbnail = styled.img`
  width: 100%;
`

const PostBox = ({
  title,
  description,
  snippet,
  date,
  tags,
  slug,
  content,
  category,
  image,
  wordCount,
  timeToRead,
  preview,
}: Post) => {
  const Heading = preview ? Heading2 : Heading1

  return (
    <Composition
      template={areasMobile}
      templateM={areasDesktop}
      gap={0}
      autoCols="1fr"
      marginBottom="1em"
      as={PostPreviewWrapper}
    >
      {(Areas) => (
        <>
          <Areas.Headline as={Parts}>
            <Heading>{preview ? <Link to={slug}>{title}</Link> : title}</Heading>
            {preview ? <div dangerouslySetInnerHTML={{ __html: snippet }} /> : null}
          </Areas.Headline>
          <Areas.Meta as={Parts}>
            <Meta>
              <strong>{date}</strong>
              <div>{timeToRead} min read</div>
            </Meta>
          </Areas.Meta>
          <Areas.Content as={ContentPart}>
            <Content>
              {image ? <Thumbnail src={image} alt={`${title} cover image`} /> : null}
              {preview ? null : <div dangerouslySetInnerHTML={{ __html: content }} />}
              {preview ? <Link to={slug}>Read more</Link> : null}
            </Content>
            <Tags tags={tags} />
          </Areas.Content>
        </>
      )}
    </Composition>
  )
}

export default PostBox
