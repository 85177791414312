import React from "react"
import styled from "styled-components"
import cc from "../../static/assets/cc.svg"
import by from "../../static/assets/by.svg"
import sa from "../../static/assets/sa.svg"

const CcImg = styled.img`
  height: 16px;
  width: 16px;
  margin-bottom: 0px;
  margin-left: 3px;
  vertical-align: text-bottom;
`

const License = ({ data }) => {
  return (
    <>
      &copy; {data.site.buildTime.split(".")[2]}
      {" | "}
      <span
        {...{
          "xmlns:dct": "http://purl.org/dc/terms/",
          "xmlns:cc": "http://creativecommons.org/ns#",
        }}
      >
        <a rel="cc:attributionURL" href="https://eyedropper.org">
          <span rel="dct:title"> All cultural works</span>
        </a>{" "}
        by{" "}
        <a rel="cc:attributionURL" href="https://kepi.cz">
          <span rel="cc:attributionName">Ondra Kudlík (Kepi)</span>
        </a>{" "}
        CC BY-SA 4.0{" "}
        <a
          href="https://creativecommons.org/licenses/by-sa/4.0"
          aria-label="Information about used Creative Commons License"
        >
          <CcImg src={cc} alt="Creative Commons License" />
          <CcImg src={by} alt="Attribution" />
          <CcImg src={sa} alt="ShareAlike" />
        </a>
      </span>
    </>
  )
}
export default License
