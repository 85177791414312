import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import { License, FooterIcon, FooterSvgIcon } from "../components"
import { Box } from "atomic-layout"

const FooterWrapper = styled.footer`
  text-align: center;
  padding: 3rem 0;
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.textAlt};

  a {
    text-decoration: none;
  }
`

interface DataProps {
  site: {
    siteMetadata: {
      title: string
    }
    buildTime: string
  }
}

const FooterMenu = styled(Box)`
  background-color: ${(props) => props.theme.colors.bg};
  padding: 1rem 2rem;
  margin-top: 2rem;
`

const SvgTwitter = () => (
  <FooterSvgIcon viewBox="328 355 335 276" xmlns="http://www.w3.org/2000/svg">
    <path
      d="
    M 630, 425
    A 195, 195 0 0 1 331, 600
    A 142, 142 0 0 0 428, 570
    A  70,  70 0 0 1 370, 523
    A  70,  70 0 0 0 401, 521
    A  70,  70 0 0 1 344, 455
    A  70,  70 0 0 0 372, 460
    A  70,  70 0 0 1 354, 370
    A 195, 195 0 0 0 495, 442
    A  67,  67 0 0 1 611, 380
    A 117, 117 0 0 0 654, 363
    A  65,  65 0 0 1 623, 401
    A 117, 117 0 0 0 662, 390
    A  65,  65 0 0 1 630, 425
    Z"
    />
  </FooterSvgIcon>
)

const SvgGithub = () => (
  <FooterSvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="m 10.441852,31.964414 c 1.040844,0.190275 1.420145,-0.452095 1.420145,-1.003805 0,-0.492967 -0.01774,-1.802002 -0.02823,-3.537592 C 6.0471825,28.67969 4.8262656,24.633812 4.8262656,24.633812 3.8799297,22.231571 2.515977,21.591743 2.515977,21.591743 0.62713531,20.300425 2.6590091,20.326128 2.6590091,20.326128 c 2.088073,0.148136 3.1863864,2.144265 3.1863864,2.144265 1.8556375,3.178722 4.8696115,2.260482 6.0547695,1.729204 0.189008,-1.344795 0.725398,-2.261757 1.320529,-2.781542 -4.6193011,-0.524892 -9.4761434,-2.310289 -9.4761434,-10.281997 0,-2.2706984 0.8109641,-4.127614 2.1417128,-5.582237 C 5.6716983,5.0276502 4.9578037,2.9127591 6.089316,0.04820766 c 0,0 1.7470838,-0.55937458 5.721447,2.13276734 1.658964,-0.4610332 3.439252,-0.6922522 5.20805,-0.6998544 1.766238,0.0076 3.546526,0.2388212 5.208044,0.6998544 3.971806,-2.69214192 5.71506,-2.13276734 5.71506,-2.13276734 1.135348,2.86455144 0.421458,4.97944254 0.206892,5.50561334 1.333301,1.454623 2.139157,3.3115386 2.139157,5.582237 0,7.992141 -4.864507,9.750719 -9.49913,10.265395 0.747108,0.642385 1.412481,1.91183 1.412481,3.853033 0,2.780267 -0.0257,5.024144 -0.0257,5.706123 0,0.556819 0.374194,1.204304 1.430361,1.001249" />
  </FooterSvgIcon>
)

const SvgChromeWS = () => (
  <>
    <FooterSvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
      <path d="M0 13.092v152.726c0 7.2 5.892 13.09 13.092 13.09H16v-.006c0-14.648 3.369-28.096 10.006-39.97l2.303-4.12 25.16 44.096H64v-.006c0-17.346 14.653-32 32-32s32 14.654 32 32v.006h4.91v-.008c0-9.61-4.336-19.236-12.21-27.113l-4.554-4.553h53.06l.722 1.525c5.018 10.582 6.072 22.234 6.072 30.143v.006h2.908c7.2 0 13.092-5.89 13.092-13.09V13.093zm74.182 17.453h43.636a8.725 8.725 0 018.727 8.728A8.724 8.724 0 01117.818 48H74.182a8.724 8.724 0 01-8.727-8.727 8.725 8.725 0 018.727-8.728zM96 98.902c14.301 0 27.713 3.576 39.854 10.631 12.733 7.403 22.19 16.566 28.904 28.02l2.357 4.015H96c-8.797 0-16.219 2.434-22.686 7.442-6.88 5.333-11.385 12.025-13.396 19.886l-1.564 6.125-26.663-43.687 1.067-1.443c6.973-9.422 16.738-17.377 28.234-23.014 10.784-5.293 22.563-7.975 35.008-7.975z" />
    </FooterSvgIcon>
  </>
)

const Footer = () => {
  const query = graphql`
    {
      site {
        buildTime(formatString: "DD.MM.YYYY")
      }
    }
  `
  const render = (data: DataProps) => {
    return (
      <>
        <FooterMenu
          flex
          gap="2rem"
          justifyContent="center"
          flexDirection="column"
          flexDirectionM="row"
        >
          <FooterIcon
            Icon={SvgGithub}
            name="Github"
            text="Report issues and feature requests."
            link="https://github.com/kepi/chromeEyeDropper/issues"
          />
          <FooterIcon
            link="https://twitter.com/eyedropper"
            Icon={SvgTwitter}
            name="Twitter"
            text="Follow the news."
          />
          <FooterIcon
            link="https://chrome.google.com/extensions/detail/hmdcmlfkchdmnmnmheododdhjedfccka"
            Icon={SvgChromeWS}
            name="Chrome Web Store"
            text="Download and leave review."
          />
        </FooterMenu>
        <FooterWrapper>
          <License data={data} />
        </FooterWrapper>
      </>
    )
  }

  return <StaticQuery query={query} render={render} />
}

export { Footer }
