import React from "react"
import { Link } from "gatsby"

const kebabCase = (string: string): string => {
  return string.replace(/\s+/g, "-").toLowerCase()
}

const Tags = ({ tags }) => {
  return (
    <>
      {tags ? (
        <>
          {tags.map((tag, i) => (
            <React.Fragment key={i}>
              <Link key={i} to={`/tags/${kebabCase(tag)}`}>
                <strong>#{tag}</strong>
              </Link>
              {i < tags.length - 1 ? `, ` : ``}
            </React.Fragment>
          ))}
        </>
      ) : null}
    </>
  )
}

export default Tags
