import React from "react"
import styled from "styled-components"

import SvgChrome from "../assets/chrome.svg"
import SvgOpencollective from "../assets/opencollective.svg"

const logos = {
  chrome: SvgChrome,
  opencollective: SvgOpencollective,
}

const HelpWrapper = styled.span`
  background-image: url(${({ logoUrl }) => logoUrl});
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: right 4px center;
  background-color: white;
  border-radius: 150px;
  display: inline-block;
  height: 40px;
  margin: 4px;
  margin-left: 0.5rem;
  width: 40px;

  transition: transform 1s ease-in-out;
`
const ButtonWrapper = styled.a`
  background-color: ${(props) => props.theme.colors.secondary};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 150px;
  color: ${(props) => props.theme.colors.bg};
  display: inline-block;
  padding-left: 0.8rem;
  text-decoration: none;
  white-space: nowrap;

  line-height: 40px;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.bg};
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.075), 0 0 8px rgba(2, 175, 233, 0.6);
    transition: all 0.2s ease-in-out;
  }

  &:hover ${HelpWrapper} {
    transform: rotate(360deg);
  }
`

const Button = ({
  to,
  logoName,
  children,
}: {
  to: string
  logoName: string
  children: React.ReactNode
}) => {
  return (
    <ButtonWrapper href={to}>
      {children}
      <HelpWrapper logoUrl={logos[logoName]}>&nbsp;</HelpWrapper>
    </ButtonWrapper>
  )
}

export default Button
