import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Box, Composition, default as AtomicLayout } from "atomic-layout"
import { Footer } from "./Footer"
/* import { Normalize } from "styled-normalize" */
import { Menu, Title, Sponsors } from "../components"

import { darkTheme } from "../theme"
import { GlobalStyles } from "../style/global"

const areas = `
title
menu
content
side
footer
`

const areasNosidebar = `
title
menu
content
footer
`

const areasM = `
title title
menu menu
content side
footer footer
/ auto 350px
`

const areasL = `
title title title
menu menu menu
. content side
footer footer footer
/ 350px auto 350px
`

AtomicLayout.configure({
  defaultBreakpointName: "s",
  breakpoints: {
    s: {
      maxWidth: 900,
    },
    m: {
      minWidth: 901,
      maxWidth: 1500,
    },
    l: {
      minWidth: 1501,
    },
  },
})

const About = styled(Box)`
  padding-top: 1rem;
  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    padding-top: 2rem;
  }
  color: ${(props) => props.theme.colors.textAlt};
`

type LayoutProps = {
  about?: JSX.Element
  nosidebar?: boolean
  children: React.ReactNode
}

const EmptySpan = <span />

const Layout = ({ about = EmptySpan, nosidebar, children }: LayoutProps) => {
  const AboutElement = () => about

  const template = areas
  const templateM = nosidebar ? areasNosidebar : areasM
  const templateL = nosidebar ? areasNosidebar : areasL

  return (
    <ThemeProvider theme={darkTheme}>
      {/* <Normalize /> */}
      <GlobalStyles />
      <Composition template={template} templateM={templateM} templateL={templateL}>
        {(Areas) => (
          <>
            <Areas.Title>
              <Title />
            </Areas.Title>
            <Areas.Menu>
              <Menu />
            </Areas.Menu>
            <Areas.Content justifyContent="center" flex>
              <Box
                paddingHorizontal="1rem"
                paddingHorizontalM="2rem"
                maxWidth="1500px"
                width="100%"
              >
                <About flex justifyContent="center">
                  <p>
                    <AboutElement />
                  </p>
                </About>
                {children}
              </Box>
            </Areas.Content>
            <Areas.Side>
              <Sponsors />
            </Areas.Side>
            <Areas.Footer>
              <Footer />
            </Areas.Footer>
          </>
        )}
      </Composition>
    </ThemeProvider>
  )
}

export default Layout
