import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button } from "../components"

const SponsorsWrapper = styled.div`
  padding-right: 0;
  text-align: center;

  @media (min-width: ${(props) => props.theme.sizes.min.l}) {
    padding-right: 2rem;
  }
  .rounded {
    border-radius: 10px;
  }
`

const A = styled.a`
  &:hover img {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.075), 0 0 8px rgba(2, 175, 233, 0.6);
    transition: all 0.2s ease-in-out;
  }
`

const OCMemberWImageWrapper = styled.img`
  border-radius: 15px;
  margin: 0;
  margin-right: 5px;
  display: inline-block;
  width: 32px;
  height: 32px;
`

const colors = ["#f5945c", "#fec76f", "#b3be62", "#75ba75", "#6dbfb8", "#71a3c1", "#be95be"]

const OCMemberWOImageWrapper = styled.div`
  background-color: ${(props) => props.color};
  display: inline-block;
  border-radius: 15px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-right: 5px;
  overflow: hidden;
  font-size: 13px;
  color: ${(props) => props.theme.colors.bgAlt};
  letter-spacing: 1px;
  font-weight: bold;
`

const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(" ")
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, "")
  return initials
}

type Member = {
  MemberId: number
  image: null | string
  totalAmountDonated: number
  lastTransactionAt: string
  name: string
}

const OCMemberWImage = ({ member }: { member: Member }) => {
  const initials = getInitials(member.name)

  return (
    <a href="https://opencollective.com/eyedropper/donate">
      <OCMemberWImageWrapper
        width="32"
        height="32"
        src={member.image}
        alt={initials}
        title={member.name}
      />
    </a>
  )
}

const OCMemberWOImage = ({ member }: { member: Member }) => {
  const initials = getInitials(member.name)
  const random_color = colors[Math.floor(Math.random() * colors.length)]
  console.log(random_color)

  return (
    <a href="https://opencollective.com/eyedropper/donate">
      <OCMemberWOImageWrapper color={random_color} title={member.name}>
        {initials}
      </OCMemberWOImageWrapper>
    </a>
  )
}

const OCMember = ({ member }: { member: Member }) => {
  return member.image ? <OCMemberWImage member={member} /> : <OCMemberWOImage member={member} />
}

const OpenCollective = () => {
  const [members, setMembers] = useState([])

  const getMembers = async () => {
    fetch("https://opencollective.com/eyedropper/members/all.json?limit=100&offset=0")
      .then((response) => response.json())
      .then((responseJson) => {
        setMembers(responseJson)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getMembers()
  }, [])

  return (
    <>
      <div>
        {members.map((member) => {
          return <OCMember key={member.MemberId} member={member} />
        })}
      </div>
    </>
  )
}

const Sponsors = () => {
  return (
    <SponsorsWrapper>
      <h3>Become a sponsor</h3>
      <Button logoName="opencollective" to="https://opencollective.com/eyedropper/donate">
        Donate to <strong>Eye Dropper</strong>
      </Button>

      <br />
      <br />
      <OpenCollective />
    </SponsorsWrapper>
  )
}

export default Sponsors
