import { transitions, fontSize } from "./common"

const darkTheme = {
  dark: true,
  colors: {
    primary: "#f5945c", // Color for buttons or links
    primaryAlt: "#fec76f",
    secondary: "#6dbfb8",
    secondaryAlt: "#71a3c1",
    text: "#fff",
    textAlt: "#fff",
    header: "#75ba75",
    headerAlt: "#be95be",
    bg: "#1d2022",
    bgAlt: "#16191b",
    bgHighlight: "#71a3c1",
    bgBorder: "#16191b",
  },
  transitions,
  fontSize,
  sizes: {
    min: {
      s: "0px",
      m: "901px",
      l: "1501px",
    },
  },
}

export default darkTheme
