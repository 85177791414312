import React from "react"
import styled from "styled-components"
import { Composition } from "atomic-layout"

const areas = `
title
img
text
`

const areasTablet = `
img title
img text
/ 400px auto
`
const FeatureBoxWrapper = styled.div`
  font-size: 0.8rem;
  .rounded {
    border-radius: 15px;
  }
`

const FeatureTitle = styled.h3`
  color: ${(props) => props.theme.colors.secondaryAlt};
  font-size: 1.5rem;
  font-weight: bold;

  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    font-size: 1.2rem;
  }
`

const FeatureDescription = styled.div`
  height: 6em;
  max-width: 20rem;
  @media (min-width: ${(props) => props.theme.sizes.min.l}) {
    text-align: center;
  }
`

type Props = {
  img: JSX.Element
  title: string
  children: JSX.Element | string
}

const FeatureBox = ({ img, title, children }: Props) => {
  const Image = img
  return (
    <FeatureBoxWrapper>
      <Composition
        template={areas}
        templateM={areasTablet}
        templateL={areas}
        alignItems="center"
        justifyItems="center"
        justifyItemsM="left"
        justifyItemsL="center"
        gap="0.5rem"
      >
        {(Areas) => (
          <>
            <Areas.Img>
              <Image />
            </Areas.Img>
            <Areas.Title>
              <FeatureTitle>{title}</FeatureTitle>
            </Areas.Title>
            <Areas.Text>
              <FeatureDescription>{children}</FeatureDescription>
            </Areas.Text>
          </>
        )}
      </Composition>
    </FeatureBoxWrapper>
  )
}

export default FeatureBox
