import styled, { css } from "styled-components"

const headings = css`
  border-radius: 0 10px 10px 0;
  color: ${(props) => props.theme.colors.bg};
  position: relative;
  padding: 0.5rem 2rem;
  display: table;
  margin-bottom: 2rem;
  left: -1.2rem;

  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    left: -2.2rem;
  }

  a {
    color: ${(props) => props.theme.colors.bg};

    &:hover {
      color: ${(props) => props.theme.colors.text};
    }
  }
`

const Heading1 = styled.h1`
  ${headings}
  background-color: ${(props) => props.theme.colors.secondary};
  margin-top: 0px;
`
const Heading2 = styled.h2`
  ${headings}
  background-color: ${(props) => props.theme.colors.header};
`
const Heading3 = styled.h3`
  ${headings}
  background-color: ${(props) => props.theme.colors.secondaryAlt};
`
const Heading4 = styled.h4`
  ${headings}
  background-color: ${(props) => props.theme.colors.primaryAlt};
`

export { Heading1, Heading2, Heading3, Heading4 }
